import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SessionsServiceApi } from './sessions.service.api';
import { SessionsServiceMock } from './sessions.service.mock';
import { environment } from '../../../environments/environment';

export interface Session {
  claims: {
    [key: string]: unknown;
  };
  token: string;
}

@Injectable({
  providedIn: 'root',
  useClass: environment.usingMocks ? SessionsServiceMock : SessionsServiceApi,
})
export abstract class SessionsService {
  abstract signIn(): Promise<void>;
  abstract signOut(): Promise<void>;
  abstract getSession(): Observable<Session | null>;
}
