import { Routes } from '@angular/router';
import { sessionGuard } from './guards/session.guard';

export const routes: Routes = [
  {
    path: '',
    canActivate: [sessionGuard],
    loadComponent: () => import('./pages/home/home.page').then((m) => m.HomePage),
  },
  {
    path: 'login',
    canActivate: [sessionGuard],
    loadComponent: () => import('./pages/login/login.page').then((m) => m.LoginPage),
  },
  {
    path: 'secrets/:id',
    canActivate: [sessionGuard],
    loadComponent: () => import('./pages/secret/secret.page').then((m) => m.SecretPage),
  },
  {
    path: '**',
    redirectTo: '/',
    pathMatch: 'full'
  }
];
