<nav class="nav">
  <div class="content">
    <a class="logo" routerLink="/">Planikun Secrets</a>
  </div>
</nav>
<router-outlet />
<footer class="footer">
  <div class="content">
    <p class="copyright">planikun-secrets</p>
  </div>
</footer>
<app-toast />