import { Injectable } from '@angular/core';
import { Session, SessionsService } from './sessions.service';
import { Observable } from 'rxjs';
import { of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SessionsServiceMock implements SessionsService {
  signIn(): Promise<void> {
    return Promise.resolve();
  }

  signOut(): Promise<void> {
    return Promise.resolve();
  }

  getSession(): Observable<Session | null> {
    return of(null);
  }
}
