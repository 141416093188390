import { Component } from '@angular/core';
import { ToastService } from '../../services/toast/toast.service';

@Component({
  selector: 'app-toast',
  imports: [],
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.scss',
})
export class ToastComponent {
  constructor(
    private toastService: ToastService,
  ) {}

  get text(): string {
    return this.toastService.getText();
  }
} 
