import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { SessionsService } from '../services/sessions/sessions.service';

export const sessionGuard = async (_: ActivatedRouteSnapshot, routerStateSnapshot: RouterStateSnapshot): Promise<boolean | UrlTree> => {
  const router = inject(Router);
  const sessionsService = inject(SessionsService);
  const session = await firstValueFrom(sessionsService.getSession());
  return true;
  if (session && routerStateSnapshot.url === '/login') {
    return router.parseUrl('/');
  } else if (!session && routerStateSnapshot.url === '/') {
    return router.parseUrl('/login');
  }
  return true;
};